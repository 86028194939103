import {useSettings} from '@wix/tpa-settings/react'
import {ExperimentNames, isEnded} from '@wix/wix-events-commons-statics'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useExperiments} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {TextButton} from 'wix-ui-tpa/cssVars'
import settingsParams from '../../../../../../settingsParams'
import {useVisibilityStyles} from '../../../../../hooks/use-visibility-styles'
import classesV2 from '../../../../classes-v2.scss'
import {Countdown} from '../../../../countdown'
import {FullDateLocation} from '../../../../full-date-location'
import {LinkToPage} from '../../../../link-to-page'
import {Ribbon} from '../../../../ribbon'
import {RsvpButton} from '../../../../rsvp-button'
import {ShortDateLocation} from '../../../../short-date-location'
import s from './content.scss'
import {classes} from './content.st.css'
import {ContentProps} from './interfaces'

export const Content = ({event, toggleOverlay, hasRibbon}: ContentProps) => {
  const {
    allBreakpoints: {
      isCardShortDateLocationVisible,
      isCardFullDateLocationVisible,
      isListAdditionalComponentsVisible,
      isListCountdownEnabled,
      isListFullDateLocationVisible_V2,
      isListShortDateLocationVisible_V2,
    },
  } = useVisibilityStyles()
  const {get} = useSettings()
  const moreInfoText = get(settingsParams.moreInfo)
  const eventEnded = isEnded(event)

  const {experiments} = useExperiments()
  const listDateLocationFormatSettingEnabled = experiments.enabled(ExperimentNames.ListDateLocationFormatSetting)

  const shortDateLocationVisible = listDateLocationFormatSettingEnabled
    ? isListShortDateLocationVisible_V2()
    : isCardShortDateLocationVisible()
  const fullDateLocationVisible = listDateLocationFormatSettingEnabled
    ? isListFullDateLocationVisible_V2()
    : isCardFullDateLocationVisible()

  return (
    <div className={classNames(s.container, {[s.updatedVisibilityVars]: listDateLocationFormatSettingEnabled})}>
      <div>
        {hasRibbon ? <Ribbon event={event} className={classNames(s.ribbon, {[s.overrideMargin]: eventEnded})} /> : null}
        {isListCountdownEnabled() && !eventEnded && (
          <div className={s.countdown}>
            <Countdown event={event} />
          </div>
        )}
        <div className={s.title}>
          <LinkToPage allowExternal event={event} dataHook="title">
            {event.title}
          </LinkToPage>
        </div>
        {shortDateLocationVisible && (
          <div className={s.shortDateLocation}>
            <ShortDateLocation
              event={event}
              dateClassName={classesV2.eventDate}
              locationClassName={classesV2.eventLocation}
            />
          </div>
        )}
        {fullDateLocationVisible && (
          <div className={s.fullDateLocation}>
            <FullDateLocation
              event={event}
              dateClassName={classesV2.eventDate}
              locationClassName={classesV2.eventLocation}
            />
          </div>
        )}
        {isListAdditionalComponentsVisible() && (
          <div className={s.moreInfoWrapper}>
            <TextButton className={classes.moreInfo} onClick={toggleOverlay} data-hook={DH.moreInfoLink(event.id)}>
              {moreInfoText}
            </TextButton>
          </div>
        )}
      </div>
      <RsvpButton event={event} />
    </div>
  )
}
